import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messageInput", "sendButton"];

  connect() {
    this.checkMessageInput();
  }

  checkMessageInput() {
    if (this.messageInputTarget.value.trim() === "") {
      this.sendButtonTarget.disabled = true;
    } else {
      this.sendButtonTarget.disabled = false;
    }
  }

  updateMessage() {
    this.checkMessageInput();
  }
}
