import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class Autoclick extends Controller {
  static messagesContainer;
  static secondMessage;
  static canClick = true;

  connect() {
    useIntersection(this);
    Autoclick.messagesContainer = document.getElementById("messages");
    Autoclick.secondMessage = Autoclick.messagesContainer.children[2];
  }

  appear(entry) {
    if (entry.isIntersecting && entry.intersectionRatio > 0 && Autoclick.canClick) {
      const nearTop = Autoclick.messagesContainer.scrollTop <= 100;

      if (nearTop) {
        Autoclick.secondMessage = Autoclick.messagesContainer.children[2];
        Autoclick.canClick = false;

        this.element.click();
        Autoclick.secondMessage.parentNode.scrollTop = Autoclick.secondMessage.offsetTop;

        setTimeout(() => {
          Autoclick.canClick = true;
        }, 2000);
     }
   }
  }
}
