import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setTextField(event) {
    const clickedText = event.target.textContent.trim();
    const textFieldId = "search_field";
    const textField = document.getElementById(textFieldId);
    if (textField) {
      textField.value = clickedText;
    }

    textField.form.requestSubmit();
  }
}
