import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"];

  connect() {
    jQuery("#select3")
      .select2()
      .on("change", (e) => {
        document.getElementById("filter-form").requestSubmit();
      });

    jQuery("#select1")
      .select2()
      .on("change", (e) => {
        document.getElementById("filter-form-colleague").requestSubmit();
      });
  }

  disconnect() {
    $(this.element).find("#select3").select2("destroy");
    $(this.element).find("#select1").select2("destroy");
  }

  submitEnd() {}

  handleRating(event, formSelector) {
    const checkboxes = this.element.querySelectorAll('input[name="stars[]"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox !== event.target) {
        checkbox.checked = false;
      }
    });

    document.querySelector(formSelector).requestSubmit();
  }

  submitEndClient(event) {
    this.handleRating(event, "#filter-form");
  }

  submitEndColleague(event) {
    this.handleRating(event, "#filter-form-colleague");
  }
}
