import { Controller } from "@hotwired/stimulus";

USER_BIO_MAX_LENGTH = 360;
export default class extends Controller {
  static targets = [
    "userBio",
    "bioReadMore",
    "userBioModal",
    "userBioTextArea",
  ];
  connect() {
    if (this.hasUserBioTarget) {
      const should_stream = this.userBioTarget.dataset.shouldStream;
      if (should_stream == "true") {
        this.typing(this.userBioTarget.dataset.bio);
      } else {
        this.userBioTarget.textContent = this.truncateText(
          this.userBioTarget.dataset.bio,
          USER_BIO_MAX_LENGTH
        );
        if (this.userBioTarget.dataset.bio.length > USER_BIO_MAX_LENGTH) {
          this.bioReadMoreTarget.classList.remove("hidden");
        }
      }
    }
  }

  typing(string) {
    const splitString = string.split("");
    let index = 0;
    const typeNextCharacter = () => {
      if (index < splitString.length) {
        this.userBioTarget.textContent += splitString[index];
        if (index > USER_BIO_MAX_LENGTH) {
          this.userBioTarget.textContent += "...";
          this.bioReadMoreTarget.classList.remove("hidden");
          return;
        }
        index++;
        setTimeout(typeNextCharacter, 10);
      }
    };
    typeNextCharacter();
  }

  toggleBio(event) {
    const completeBio = this.userBioTarget.dataset.bio;

    if (event.currentTarget.textContent == "read more") {
      event.currentTarget.textContent = "read less";
      this.userBioTarget.textContent = completeBio;
    } else {
      event.currentTarget.textContent = "read more";

      this.userBioTarget.textContent = this.truncateText(
        completeBio,
        USER_BIO_MAX_LENGTH
      );
    }
  }

  openUserBioModal(e) {
    e.preventDefault();

    document.getElementById("userBioTextArea").value =
      document.getElementById("userBioText").dataset.bio;
    document.getElementById("userBioModal").classList.remove("hidden");
  }

  truncateText(text, length) {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + "...";
  }
}
