import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  reset() {
    this.element.reset();
  }

  resetAndDisable() {
    this.element.reset();
    this.element.getElementsByClassName("chat_field")[0].disabled = true;
  }
}
