import { Controller } from "@hotwired/stimulus";

let timeout;
let typing = true;
let charIndex = 0;
let textIndex = 0;
export default class extends Controller {
  connect() {
    document.addEventListener("keydown", this.handleKeyPress.bind(this));

    setTimeout(() => {
      this.submitChatIfHasChatQueryInURL();
    }, 500);

    clearTimeout(timeout);
    this.typingEffectForAiSearch();
  }

  typingEffectForAiSearch() {
    const searchField = document.getElementById("search_field");
    if (searchField == null || !searchField) return;

    let typingText = [
      "I am looking for an Ai policy specialist in Washington, D.C.",
      "I am looking for a PR expert to help me get publicity for my clean energy startup",
      "I am looking for a fashion sustainability expert to write an impact report",
      "I want to run for town council and need someone to help me get started",
    ];
    this.type(typingText, searchField);
  }

  type(texts, input) {
    if (typing) {
      if (charIndex < texts[textIndex].length) {
        input.placeholder += texts[textIndex].charAt(charIndex);
        charIndex++;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = false;
        timeout = setTimeout(() => this.type(texts, input), 1000);
      }
    } else {
      if (charIndex > 0) {
        input.placeholder = input.placeholder.substring(0, charIndex - 1);
        charIndex--;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = true;
        textIndex = (textIndex + 1) % texts.length;
        timeout = setTimeout(() => this.type(texts, input), 200);
      }
    }
  }

  submitChatIfHasChatQueryInURL() {
    const chat_query = new URLSearchParams(window?.location?.search).get(
      "chat_query"
    );
    if (chat_query != "" && chat_query != null) {
      $("#search_field").val(chat_query);
      this.element.requestSubmit();
      history.pushState({}, "", window.location.origin);
    }
  }

  disconnect() {
    clearTimeout(timeout);
    document.removeEventListener("keydown", this.handleKeyPress.bind(this));
  }
  handleKeyPress(event) {
    if (event.ctrlKey && event.key === "k") {
      event.preventDefault();
      this.focusSearchField();
    }
  }

  focusSearchField() {
    const searchField = this.element.querySelector("#search_field");
    if (searchField) {
      searchField.focus();
    }
  }

  animateAISearchElement(event) {
    const mapbox = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="mapbox"]'),
      "mapbox"
    );

    if ($("#__animate_ai_search").hasClass("animate_ai_search")) {
      $("#__animate_ai_search").removeClass("animate_ai_search");
      event.currentTarget.classList.add("!bottom-[10px]", "!right-[10px]");
      $(".tooglewrapper").css({
        transform: "translate(-0%)",
        left: "10px",
        bottom: "10px",
      });

      mapbox.flyToRandomProfiles();
    } else {
      $(".tooglewrapper").css({
        transform: "translate(-50%)",
        left: "50%",
        bottom: "30px",
      });

      mapbox.flyToRandomProfiles(false);

      $("#__animate_ai_search").addClass("animate_ai_search");
      event.currentTarget.classList.remove("!bottom-[10px]", "!right-[10px]");
    }
  }

  clearField(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      const submitButton = event.currentTarget.form.querySelector("button");
      if (submitButton) {
        submitButton.click();
      }
      event.currentTarget.value = "";
    }
  }

  toggleChat() {
    $(".icon-x").toggleClass("hidden");
    $(".tooglearea").slideToggle(300);
    $(".tooglewrapper").toggleClass("tooglewrapper-open");
  }

  handleKeyUp(event) {
    if (event.key === "Enter") {
      document.getElementById("search_field").value = "";
    }
  }

  submitAiChat(event) {
    event.preventDefault();
    event.currentTarget.form.requestSubmit();
  }

  activeAIChat(event) {
    event.preventDefault();
    document.getElementById("ai_chat").value = true;
    this.element.submit();
  }
}
