import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["locationList"];
  static values = { batchSize: Number };

  connect() {
    this.currentPage = 1;
    this.loadPage();
  }

  loadPage() {
    const locationsList = this.locationListTarget;
    const locations = locationsList.querySelectorAll("li");

    const startIndex = (this.currentPage - 1) * this.batchSizeValue;
    const endIndex = startIndex + this.batchSizeValue;

    for (let i = startIndex; i < endIndex; i++) {
      const location = locations[i];
      if (location) {
        location.classList.remove("hidden");
      } else {
        this.element.querySelector("button").style.display = "none"; // Hide the "Load More" button when all locations are loaded
        break;
      }
    }

    this.currentPage++;
  }
}
