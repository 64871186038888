import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "socialProfileContainer",
    "selectAllButton",
    "checkbox",
    "searchInput",
    "profileList",
    "profileCheckbox",
    "linkField",
    "template",
  ];

  connect() {
    this.selectedProfilesContainer = document.getElementById(
      "selected-profiles-container"
    );
    this.selectedProfiles = new Set();
  }

  toggleSelectAll(event) {
    const allChecked =
      this.selectAllButtonTarget.textContent === "Deselect All";
    this.profileCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = !allChecked;
      this.updateSelectedProfiles(checkbox);
    });
    this.selectAllButtonTarget.textContent = allChecked
      ? "Select All"
      : "Deselect All";
  }

  updateSelectedProfiles(event) {
    const checkbox = event.target || event;
    const profileId = checkbox.value;

    if (checkbox.checked) {
      this.addHiddenField(profileId);
      this.selectedProfiles.add(profileId);
    } else {
      this.removeHiddenField(profileId);
      this.selectedProfiles.delete(profileId);
    }
  }

  addHiddenField(profileId) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "selected_profiles[]";
    input.value = profileId;
    input.id = `selected-profile-${profileId}`;
    this.selectedProfilesContainer.appendChild(input);
  }

  removeHiddenField(profileId) {
    const input = document.getElementById(`selected-profile-${profileId}`);
    if (input) {
      input.remove();
    }
  }

  search(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    this.timeout = setTimeout(() => {
      this.getSearchResults(value);
    }, 800);
  }

  getSearchResults(query) {
    const url = new URL(
      "/signup_flow/search_recommended_profiles",
      window.location.origin
    );
    url.searchParams.set("content", query);

    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        setTimeout(() => this.reapplyCheckedState(), 100);
      });
  }

  reapplyCheckedState() {
    this.profileCheckboxTargets.forEach((checkbox) => {
      if (this.selectedProfiles.has(checkbox.value)) {
        checkbox.checked = true;
      }
    });
  }

  addLinks(event) {
    event.preventDefault();

    const newField = event.target.parentElement.cloneNode(true);
    newField.classList.add("mt-2");

    event.currentTarget.previousElementSibling.value = "";

    const plusIcon = newField.querySelector("i");
    plusIcon.classList.remove("icon-plus");
    plusIcon.classList.add("icon-trash");
    plusIcon.setAttribute("data-action", "click->claim-profile#removeLinks");

    document.getElementById("__signup_links").append(newField);
  }

  removeLinks(event) {
    event.preventDefault();

    if (this.socialProfileContainerTarget.childElementCount > 1) {
      event.target.closest(".relative").remove();
    }
  }

  upload(event) {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      // If a file is selected, submit the form
      this.element.closest("form").submit();
    }
  }

  addLink(event) {
    const link = this.linkFieldTarget.value;

    if (link == "") {
      const spanElement = this.linkFieldTarget.nextElementSibling;
      if (spanElement && spanElement.tagName === "SPAN") {
        spanElement.textContent = "This field is required";
      }
      return;
    }

    const iconTag = document.getElementById("link_image");
    const linkType = iconTag.dataset.linkType || "link";

    const newElement = this.templateTarget.cloneNode(true);
    newElement.classList.remove("hidden");

    const img = newElement.querySelector("img");
    const input = newElement.querySelector("input");
    const iconPath = iconTag.src;
    img.src = iconPath;
    input.value = link;
    input.name = linkType;

    this.linkFieldTarget.value = "";

    const element = event?.currentTarget?.parentNode?.parentNode;
    if (this.totalSocialList() >= 3) {
      document
        .getElementById("social_dropdown_list")
        .classList.add("overflow-auto", "max-h-[160px]", "scrollbar");
    }

    if (element && element.tagName == "LI") {
      element.insertAdjacentElement("afterend", newElement);
    }

    this.updateLinkData();
  }

  handleKeyup(e) {
    if (e.target.value != "") {
      e.target.classList.remove(
        "!border-1",
        "!border-rose-400",
        "!placeholder-rose-600"
      );
      const spanElement = e.target.nextElementSibling;

      if (spanElement && spanElement.tagName === "SPAN") {
        e.target.classList.remove("!border-1", "!border-rose-400");
        spanElement.textContent = "";
      }
    }
  }

  updateLinkData() {
    const links = [];
    this.element.querySelectorAll("li").forEach((li) => {
      const input = li.querySelector("input");
      if (input) {
        const linkType = input.name;
        const linkUrl = input.value;
        const linkObj = {};
        linkObj["network"] = linkType;
        linkObj["url"] = linkUrl;
        if (linkType && linkUrl) {
          links.push(linkObj);
        }
      }
    });
    const linkDataField = document.getElementById("link_data");
    linkDataField.value = JSON.stringify(links);
  }

  totalSocialList() {
    const list = document.getElementById("social_dropdown_list");
    return list.children.length;
  }
}
