import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!jQuery("#skill_search").data("select2")) {
      jQuery("#skill_search").select2({
        ajax: {
          delay: 250,
          url: "/search/skills_search",
          dataType: "json",
          data: (params) => {
            return {
              q: params.term,
            };
          },
          processResults: (data) => {
            return {
              results: data.map((item) => {
                return { id: item[1], text: item[0] };
              }),
            };
          },
          cache: false,
        },
        multiple: true,
        placeholder: "Select an option",
      });
    }
  }
}
