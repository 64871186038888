import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.messages = document.getElementById("messages");
    this.observer = new MutationObserver(this.handleMutation.bind(this));
    this.observer.observe(this.messages, { childList: true });

    this.resetScroll();
  }

  disconnect() {
    this.observer.disconnect();
  }

  resetScroll() {
    this.messages.scrollTop =
      this.messages.scrollHeight - this.messages.clientHeight;
  }

  handleMutation(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (
        mutation.type === "childList" &&
        mutation.addedNodes.length === 2 &&
        mutation.addedNodes[0].nodeName.toLowerCase() === "li"
      ) {
        this.resetScroll();
      }
    });
  }
}
