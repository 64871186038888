import { Controller } from "@hotwired/stimulus";

let lineupID = null;

export default class extends Controller {
  connect() {
    $(".aside-dropdown > .dropdown-main").on("click", (e) => {
      $(this).parent().toggleClass("sub-menu-show");
    });
    $(document).click(function (event) {
      if (!$(event.target).closest(".dropdown").length) {
        $(".dropdown").addClass("hidden");
      }
    });
  }

  askForRemove(el) {
    lineupID = el?.currentTarget?.dataset?.id;
    console.log(lineupID);
    document
      .getElementById("delete-similar-profile")
      ?.classList?.remove("hidden");
  }

  removeProfile() {
    if (lineupID == null) {
      return;
    }
    fetch(`${window.location.origin}/lineups/${lineupID}`, {
      method: "Delete",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        document
          .getElementById("delete-similar-profile")
          ?.classList?.add("hidden");
      });
  }

  searchProfiles(el) {
    clearTimeout(this.timeout);
    const value = el.target.value ?? "";
    this.timeout = setTimeout(() => {
      this.search(value);
    }, 800);
  }

  search(value) {
    fetch(
      `${window.location.origin}/lineups/similar_profiles?search=${value}`,
      {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      }
    )
      .then((response) => response.text())
      .then((html) => {
        $(".dropdown").removeClass("hidden");
        Turbo.renderStreamMessage(html);
      });
  }

  searchCompanies(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    const companyIndex = el.target.dataset.companyIndex;
    document.getElementById("company_key_" + companyIndex).value = "";
    this.timeout = setTimeout(() => {
      this.searchCompany(companyIndex, value);
    }, 800);
  }

  searchCompany(companyIndex, value) {
    const dropdown = $(`.dropdown_${companyIndex}`);
    if (value == "") {
      dropdown.addClass("hidden");
      $("#search-companies").val("");
      return;
    }
    fetch(
      `${window.location.origin}/people/similar_companies?search=${value}&company_index=${companyIndex}`,
      {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      }
    )
      .then((response) => response.text())
      .then((html) => {
        if (html.trim() === "") {
          dropdown.addClass("hidden");
        } else {
          dropdown.removeClass("hidden");
          Turbo.renderStreamMessage(html);
        }
      });
  }

  selectCompanyName(event) {
    const companyName = event.currentTarget.dataset.company;
    const companyKey = event.currentTarget.dataset.companyKey;
    const companyIndex = event.currentTarget.dataset.companyIndex;
    document.getElementById("company_" + companyIndex).value = companyName;
    document.getElementById("company_key_" + companyIndex).value = companyKey;

    document.getElementById("search-companies_" + companyIndex).value =
      companyName;

    let elements = document.getElementsByClassName(
      "companies_drop_" + companyIndex
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("hidden");
    }
  }

  handleSubmit(element) {
    element.preventDefault();
    element.currentTarget.form.requestSubmit();
    element.target?.classList?.add("hidden");
    $(".dropdown").addClass("hidden");
    $("#search-profiles").val("");
  }

  modalClickToHide(event) {
    let elements = document.getElementsByClassName("drop_down_hide");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("hidden");
    }
  }
}
