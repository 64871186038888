$(document).ready(function () {
  let searchbar = $(".banner-search-bar");

  if (searchbar.length >= 1) {
    particlesJS.load("particles", "particles.json", function () {});
    let animated_Text = searchbar.data("placeholder-text")?.split("|");
    new Typed(searchbar.get(0), {
      strings: animated_Text,
      typeSpeed: 30,
      backSpeed: 20,
      backDelay: 500,
      startDelay: 1200,
      loop: true,
      attr: "placeholder",
      showCursor: true,
      cursorChar: "|",
      autoInsertCss: true,
      bindInputFocusEvents: true,
      smartBackspace: false,
      onStop: function (pos, self) {
        setTimeout(function () {
          self.el.placeholder = "";
        }, 100);
      },
    });
  }
});
