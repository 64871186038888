import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.messages = document.getElementsByClassName("chat_messages")[0];
    this.observer = new MutationObserver(this.handleMutation.bind(this));
    this.observer.observe(this.messages, { childList: true });

    this.resetScroll();
  }

  disconnect() {
    this.observer.disconnect();
  }

  resetScroll() {
    this.messages.scrollTop =
      this.messages.scrollHeight - this.messages.clientHeight;
  }

  handleMutation(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (
        mutation?.type === "childList" &&
        mutation?.addedNodes[0]?.nodeName?.toLowerCase() === "div" &&
        mutation?.addedNodes[0]?.classList?.contains("professionals_result")
      ) {
        setTimeout(() => {
          this.resetScroll();
        }, 1000);
        return;
      }
    });

    this.resetScroll();
  }
}
