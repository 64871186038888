import { Controller } from "@hotwired/stimulus";
let timeout;
let typing = true;
let charIndex = 0;
let textIndex = 0;
export default class extends Controller {
  static targets = ["tab", "input"];

  connect() {
    if (window.location.href.includes("people")) {
      $("#ai_search_dropdown_filter")?.select2();
    }
    this.closeSearchDropdown();
    clearTimeout(timeout);
    this.typingEffectForAiSearch();
  }

  typingEffectForAiSearch() {
    const searchField = document.getElementById("search_field");
    if (searchField == null || !searchField) return;
    let typingText = [];
    if (
      window.location.href.includes("search") ||
      window.location.href.includes("professional")
    ) {
      typingText = [
        "Bruce",
        "Alex",
        "Tim",
        "Olivia",
        "Sophia",
        "Emma",
        "Isabella",
        "Charlotte",
      ];
    }
    if (window.location.href.includes("skills")) {
      typingText = [
        "Project Management",
        "Communication",
        "Teamwork",
        "Leadership",
        "Problem Solving",
        "Time Management",
        "Critical Thinking",
      ];
    }
    if (window.location.href.includes("companies")) {
      typingText = [
        "Apple Inc.",
        "Microsoft Corporation",
        "Alphabet Inc. (Google)",
        "Amazon.com, Inc.",
        "Meta Platforms Inc. (Facebook)",
        "NVIDIA Corporation",
      ];
    }
    if (typingText.length != 0) {
      this.type(typingText, searchField);
    }
  }

  closeSearchDropdown() {
    document.addEventListener("click", (event) => {
      const search_dropdown = window.location.href.includes("companies")
        ? document.getElementById("companies_list")
        : document.getElementById("suggestions_list");

      const search_wrapper = document.getElementById("search_wrapper");
      if (
        search_wrapper &&
        !search_wrapper.contains(event.target) &&
        !search_dropdown?.firstElementChild?.classList.contains("hidden")
      ) {
        search_dropdown?.firstElementChild?.classList.add("hidden");
      }
    });
  }

  switchTab(event) {
    event.preventDefault();

    const tabTargetId = event.target.dataset.searchTarget;
    const tabContent = document.getElementById(tabTargetId);

    if (tabContent) {
      const allTabs = tabContent?.parentNode
        ?.querySelector("ul")
        ?.querySelectorAll("li");

      allTabs?.forEach((tab) => {
        tab?.firstElementChild?.classList?.remove("active");
      });

      const allTabContents = tabContent.parentNode.querySelectorAll(
        "[data-turbo-frame='tab-content']"
      );
      allTabContents?.forEach((content) => {
        content.classList.add("hidden");
      });

      event.target.classList.add("active");
      tabContent.classList.remove("hidden");
    }
  }

  suggestionSearch(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    const dropdown = document.getElementById("suggestions_list");
    if (value == "") {
      dropdown?.firstElementChild?.classList.add("hidden");
      return;
    }
    this.timeout = setTimeout(() => {
      this.getSearchResults(value);
    }, 500);
  }

  getSearchResults(query) {
    const url = new URL("/search/suggestion", window.location.origin);
    url.searchParams.set("q", query);

    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 300);
  }

  selectAndSearch(event) {
    this.inputTarget.value = event.currentTarget.dataset.search;
    const search_dropdown = document.getElementById("suggestions_list");
    search_dropdown?.firstElementChild?.classList.add("hidden");
    this.element.requestSubmit();
  }

  type(texts, input) {
    if (typing) {
      if (charIndex < texts[textIndex].length) {
        input.placeholder += texts[textIndex].charAt(charIndex);
        charIndex++;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = false;
        timeout = setTimeout(() => this.type(texts, input), 1000);
      }
    } else {
      if (charIndex > 0) {
        input.placeholder = input.placeholder.substring(0, charIndex - 1);
        charIndex--;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = true;
        textIndex = (textIndex + 1) % texts.length;
        timeout = setTimeout(() => this.type(texts, input), 200);
      }
    }
  }
}
