import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-request"
export default class extends Controller {
  static targets = [
    "requestModal",
    "firstName",
    "lastName",
    "linkField",
    "template",
    "successModal",
  ];
  requestProfileModal() {
    this.requestModalTarget.classList.remove("hidden");
  }

  requestProfileModalClose() {
    this.requestModalTarget.classList.add("hidden");
  }

  toggleSubmenu(event) {
    $(this.element).toggleClass("sub-menu-show");
  }

  addLink(event) {
    const link = this.linkFieldTarget.value;

    if (link == "") {
      const spanElement = this.linkFieldTarget.nextElementSibling;
      if (spanElement && spanElement.tagName === "SPAN") {
        spanElement.textContent = "This field is required";
      }
      return;
    }

    const iconTag = document.getElementById("link_image");
    const linkType = iconTag.dataset.linkType || "link";

    const newElement = this.templateTarget.cloneNode(true);
    newElement.classList.remove("hidden");

    const img = newElement.querySelector("img");
    const input = newElement.querySelector("input");
    const iconPath = iconTag.src;
    img.src = iconPath;
    input.value = link;
    input.name = linkType;

    this.linkFieldTarget.value = "";

    const element = event?.currentTarget?.parentNode?.parentNode;
    if (this.totalSocialList() >= 3) {
      document
        .getElementById("social_dropdown_list")
        .classList.add("overflow-auto", "max-h-[160px]", "scrollbar");
    }

    if (element && element.tagName == "LI") {
      element.insertAdjacentElement("afterend", newElement);
    }

    this.updateLinkData();
  }

  selectLink(event) {
    const inputElement = document.getElementById("linkField");
    const val = event?.currentTarget?.dataset?.value;
    const iconTag = document.getElementById("link_image");
    iconTag.src = `/images/${val}.svg`;
    iconTag.dataset.linkType = val; // Set the data-link-type attribute
    inputElement.name = val;
    $("#link_btn").toggleClass("sub-menu-show");
  }

  updateLinkData() {
    const links = [];
    this.element.querySelectorAll("li").forEach((li) => {
      const input = li.querySelector("input");

      if (input) {
        const linkType = input.name;
        const linkUrl = input.value;
        const linkObj = {};
        linkObj[linkType] = linkUrl;
        if (linkType && linkUrl) {
          links.push(linkObj);
        }
      }
    });

    const linkDataField = document.getElementById("link_data");
    linkDataField.value = JSON.stringify(links);
  }

  delete(event) {
    event.target.closest("li").remove();
    this.updateLinkData();

    if (this.totalSocialList() >= 3) {
      document
        .getElementById("social_dropdown_list")
        .classList.remove("overflow-auto", "max-h-[160px]", "scrollbar");
    }
  }

  handleKeyup(e) {
    if (e.target.value != "") {
      e.target.classList.remove(
        "!border-1",
        "!border-rose-400",
        "!placeholder-rose-600"
      );
      const spanElement = e.target.nextElementSibling;

      if (spanElement && spanElement.tagName === "SPAN") {
        e.target.classList.remove("!border-1", "!border-rose-400");
        spanElement.textContent = "";
      }
    }
  }

  submitRequest(event) {
    event.preventDefault();

    const first_name = this.firstNameTarget;
    const last_name = this.lastNameTarget;

    const ERROR_MESSAGE = "This field is required";

    if (first_name.value == null || first_name.value == "") {
      this.errorMessage(first_name, ERROR_MESSAGE);
    }

    if (last_name.value == null || last_name.value == "") {
      this.errorMessage(last_name, ERROR_MESSAGE);
    }

    this.updateLinkData();

    const linkDataField = document.getElementById("link_data");
    const value = linkDataField.value;

    if (value == "" || value == "[]") {
      const link = document.getElementById("linkField");
      link.classList.add("!placeholder-rose-600");
      return;
    }

    if (first_name.value != "" && last_name.value != "") {
      event.target.form.requestSubmit();
      this.requestModalTarget.classList.add("hidden");
      document.getElementById("request-success").classList.remove("hidden");
      setTimeout(() => {
        document.getElementById("request-success").classList.add("hidden");
      }, 3000);
    }
  }

  preview(event) {
    const input = event.target;

    // in KB
    const FILE_SIZE_VALIDATION = 5000;

    const PDF_SVG_ICON = `<svg class="w-5 h-5" viewBox="0 0 512 512">
    <path d="M64 464l48 0 0 48-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 304l-48 0 0-144-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"/></svg>`;

    const WORD_FILE_SVG_ICON = `<svg class="w-5 h-5" viewBox="0 0 384 512"><path d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm55 241.1c-3.8-12.7-17.2-19.9-29.9-16.1s-19.9 17.2-16.1 29.9l48 160c3 10.2 12.4 17.1 23 17.1s19.9-7 23-17.1l25-83.4 25 83.4c3 10.2 12.4 17.1 23 17.1s19.9-7 23-17.1l48-160c3.8-12.7-3.4-26.1-16.1-29.9s-26.1 3.4-29.9 16.1l-25 83.4-25-83.4c-3-10.2-12.4-17.1-23-17.1s-19.9 7-23 17.1l-25 83.4-25-83.4z"/></svg>`;

    const LOADING_SVG_ICON = `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>`;

    if (input.files && input.files[0]) {
      const resume_block = document.getElementById("resume_block");
      const resume_iconn = document.getElementById("resume_icon");
      const submitButton = document.getElementById("submit_button");
      const deleteButton = document.getElementById(
        "profile_request_delete_button"
      );

      deleteButton.classList.add("hidden");
      resume_iconn.innerHTML = LOADING_SVG_ICON;
      submitButton.setAttribute("disabled", true);
      resume_block.classList.add("bg-black", "opacity-80", "rounded-lg");
      submitButton.classList.add("opacity-50", "cursor-not-allowed");

      setTimeout(() => {
        const file = input.files[0];

        const reader = new FileReader();
        const fileSizeInBytes = file.size;
        const fileSizeInMB = fileSizeInBytes / 1024;

        const fileName = file.name;
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        const resume = document.getElementById("prev-image");
        resume.removeAttribute("name");
        deleteButton.classList.remove("hidden");

        const icon = document.getElementById("resume_icon");
        icon.innerHTML = "";

        if (fileSizeInMB > FILE_SIZE_VALIDATION) {
          resume_block.classList.remove("bg-black", "opacity-80", "rounded-lg");
          submitButton.removeAttribute("disabled");
          submitButton.classList.remove("opacity-50", "cursor-not-allowed");

          deleteButton.classList.add("hidden");
          icon.innerHTML = "";
          icon.innerHTML = `<i class="icon-plus text-text_color"></i>`;
          this.resumeValidationError(`File size is greater than 5 MB`);
          return;
        }

        const fileNameWithoutExtension = fileName.substring(
          0,
          fileName.lastIndexOf(".")
        );

        const iconFileName = `<span class="text-xs font-medium mt-2" title="${fileNameWithoutExtension}">${
          fileNameWithoutExtension.length > 10
            ? fileNameWithoutExtension.substring(10, fileNameWithoutExtension) +
              ".."
            : fileNameWithoutExtension
        }</span>`;

        const resume_error = document.getElementById("resume_error");
        if (resume_error) {
          resume_error.remove();
        }

        if (fileExtension === "pdf") {
          icon.innerHTML = PDF_SVG_ICON + iconFileName;
        } else if (fileExtension === "docx" || fileExtension === "doc") {
          icon.innerHTML = WORD_FILE_SVG_ICON + iconFileName;
          iconFileName;
        } else {
          resume_block.classList.remove("bg-black", "opacity-80", "rounded-lg");
          submitButton.removeAttribute("disabled");
          submitButton.classList.remove("opacity-50", "cursor-not-allowed");

          icon.innerHTML = "";
          icon.innerHTML = `<i class="icon-plus text-text_color"></i>`;
          deleteButton.classList.add("hidden");
          this.resumeValidationError(
            `Only pdf, doc and docx extensions allowed`
          );
          return;
        }

        if (resume.getAttribute("name") == null) {
          resume.setAttribute("name", `profile_request[attachment]`);
        }

        reader.readAsDataURL(input.files[0]);

        resume_block.classList.remove("bg-black", "opacity-80", "rounded-lg");
        submitButton.removeAttribute("disabled");
        submitButton.classList.remove("opacity-50", "cursor-not-allowed");
      }, 1500);
    }
  }

  resumeValidationError(message) {
    const resume_file_detail = document.getElementById("resume_file_desc");
    const spanElement = document.createElement("span");

    if (spanElement) {
      const resume_error = document.getElementById("resume_error");
      if (resume_error) {
        resume_error.remove();
      }

      spanElement.textContent = message;
      spanElement.classList.add("!text-rose-400", "text-sm");
      spanElement.setAttribute("id", "resume_error");
      resume_file_detail.insertAdjacentElement("afterend", spanElement);
      return false;
    }
  }

  errorMessage(element, error_message) {
    const spanElement = element.nextElementSibling;
    if (spanElement && spanElement.tagName === "SPAN") {
      element.classList.add("!border-1", "!border-rose-400");
      spanElement.textContent = error_message;
    }
  }

  deleteFile(event) {
    const icon = document.getElementById("resume_icon");
    icon.innerHTML = "";
    icon.innerHTML = `<i class="icon-plus text-text_color"></i>`;
    const file = document.getElementById("prev-image");
    file.removeAttribute("name");

    const profile_request_delete_button = document.getElementById(
      "profile_request_delete_button"
    );
    profile_request_delete_button.classList.add("hidden");

    const resume_block = document.getElementById("resume_block");
    resume_block.classList.remove("bg-black", "opacity-80", "rounded-lg");
  }

  totalSocialList() {
    const list = document.getElementById("social_dropdown_list");
    return list.children.length;
  }
}
