import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company"
export default class extends Controller {
  connect() {}

  submitDisputeForm() {
    const form = document.getElementById("dispute-form");
    const review_id = document.getElementById("dispute_disputable_id").value;
    const type = document.getElementById("dispute_dispute_type").value;
    const msg = document.getElementById("dispute_message").value;

    const dispute_disputable_type = document.getElementById(
      "dispute_disputable_type"
    ).value;
    if (
      dispute_disputable_type != null &&
      dispute_disputable_type != "" &&
      dispute_disputable_type === "Post"
    ) {
      const element = document.getElementById(`community_post_${review_id}`);
      element.removeAttribute("data-controller");
      element.removeAttribute('data-action="click->review#flag_toggle"');
      element.removeAttribute(`data-post-target="${review_id}"`);
      element.querySelector("span").textContent = "Flagged";
    }

    if (form && type !== "" && msg !== "") {
      form.requestSubmit();
      form.reset();
      this.element.classList.add("hidden");
      this.element.style.display = "none";
      $("body").removeClass("modal-open-reportIssue");
      const flag_link = document.getElementById(`flag-link-${review_id}`);
      if (flag_link) {
        flag_link.remove();
      }
    }
  }

  openModal() {
    document.getElementById("reportRemarks").classList.remove("hidden");
  }
}
