import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput"];
  connect() {}

  company_show_more(event) {
    this.handleShowMoreAndShowLess(event, "similar_company", 5);
    var element = document.querySelector("#similar_companies_list");
    element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
  }

  location_show_more(event) {
    this.handleShowMoreAndShowLess(event, "company_location", 4);

    var element = document.querySelector("#location_list");
    element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
  }

  handleShowMoreAndShowLess(event, targetClass, currently_show) {
    const record = $(`.${targetClass}.hidden`);

    if (event.target.textContent == "Show more") {
      const slicing = record.slice(0, currently_show);
      slicing.removeClass("hidden");

      if (record.length <= currently_show) {
        event.target.textContent = "Show less";
      }
    } else {
      $(`.${targetClass}`).each((i, el) => {
        if (i >= currently_show) {
          $(el).addClass("hidden");
        }
      });
      event.target.textContent = "Show more";
    }
  }

  search(el) {
    clearTimeout(this.timeout);
    const value = el.target.value;
    const search_dropdown = document.getElementById("companies_list");
    if (value == "") {
      search_dropdown?.firstElementChild?.classList.add("hidden");
      // this.element.requestSubmit();
      return;
    }
    this.timeout = setTimeout(() => {
      this.renderCompanies(value);
    }, 800);
  }

  renderCompanies(value) {
    fetch(`${window.location.origin}/companies/search?q=${value}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  submitByEmployeeCount(event) {
    event.currentTarget.form.requestSubmit();
  }

  selectAndSearchCompany(event) {
    this.searchInputTarget.value = event.currentTarget.dataset.company;
    const search_dropdown = document.getElementById("companies_list");
    search_dropdown?.firstElementChild?.classList.add("hidden");
    this.element.requestSubmit();
  }
}
