import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let elements = document.getElementsByClassName("desktop_clickable");

    let is_mobile = window.matchMedia(
      "only screen and (max-width: 1024px)"
    ).matches;

    if (is_mobile) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute("href", "javascript:void(0)");
        elements[i].removeAttribute("target");
      }
    }
  }
}
