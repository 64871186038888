import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lead"
export default class extends Controller {
  connect() {}

  filterByDate(event) {
    const value = event.target.value;

    fetch(`${window.location.origin}/dashboard/leads?date=${value}`, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }
}
