import consumer from "./consumer"

let resetFunc;

consumer.subscriptions.create("AppearanceChannel", {
  initialized() {},

  connected() {
    resetFunc = () => this.resetTimer(this.uninstall)
    this.install();
    window.addEventListener("turbo:load", () => this.resetTimer())
  },

  disconnected() {
    this.uninstall();
  },

  rejected() {
    this.uninstall();
  },

  received(data) {
    // Data received
  },

  online() {
    this.perform('online');
  },

  offline() {
    this.perform('offline');
  },

  uninstall() {
    const shouldRun = document.getElementById("appearance_channel");
    if (!shouldRun){
      this.perform("offline");
    }
  },
  
  install() {
    window.removeEventListener("load", resetFunc);
    window.removeEventListener("DOMContentLoaded", resetFunc);

    window.addEventListener("load", resetFunc);
    window.addEventListener("DOMContentLoaded", resetFunc);

    this.resetTimer();
  },

  resetTimer() {
    this.uninstall();
    const shouldRun = document.getElementById("appearance_channel");

    if (!!shouldRun) {
      this.online();
    }
  }
});
