import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["moreTags", "textarea", "counter"];

  connect() {
    this.textareaTargets.forEach((textarea, index) => {
      textarea.addEventListener("input", () => {
        this.updateCounter(textarea, index);
      });
      this.updateCounter(textarea, index);
    });
  }

  updateCounter(textarea, index) {
    const maxLength = parseInt(textarea.getAttribute("maxlength")) || 767;
    const remaining = maxLength - textarea.value.length;
    const counter = this.counterTargets[index];
    if (counter) {
      counter.textContent = `${remaining} characters remaining`;
      if (remaining <= 0) {
        textarea.value = textarea.value.substring(0, maxLength);
        counter.textContent = "0 characters remaining";
      }
    }
  }

  toggle(event) {
    const reviewId = event.currentTarget.getAttribute("data-review-target");
    $(`[data-review-target="replyForm-${reviewId}"]`).toggleClass("hidden");
  }

  flag_toggle(event) {
    event.preventDefault();
    const reviewId = event.currentTarget.getAttribute("data-review-target");
    if (reviewId != null && reviewId != "") {
      document.getElementById("dispute_disputable_id").value = reviewId;
      document.getElementById("dispute_disputable_type").value = "Review";
    } else {
      const postID = event.currentTarget.getAttribute("data-post-target");
      document.getElementById("dispute_disputable_id").value = postID;
      document.getElementById("dispute_disputable_type").value = "Post";
    }

    document.getElementById("reportIssue").classList.remove("hidden");
  }

  closeModal() {
    document.getElementById("reportIssue").classList.add("hidden");
  }

  updateReviewBody(event) {
    const reviewBodyTarget = this.element.querySelector("#reviewbody");
    reviewBodyTarget.textContent = event.target.value;
  }

  open_modal() {
    const radioButtons = document.getElementsByClassName("anonymously-radio");
    let selectedValue = null;

    for (let i = 0; i < radioButtons.length; i++) {
      if (radioButtons[i].checked) {
        selectedValue = radioButtons[i].value;
        break;
      }
    }

    const reviewUserElement = document.querySelector(".review_user");
    const reviewerAvatar = document.querySelector(".reviewer_avatar");

    if (selectedValue === "1") {
      if (!reviewerAvatar.dataset.originalAvatar) {
        reviewerAvatar.dataset.originalAvatar = reviewerAvatar.src;
      }
      reviewUserElement.textContent = "Anonymous";
      reviewerAvatar.src = `${window.location.origin}/images/anonymous-img.png`;
    } else {
      const name = reviewUserElement.dataset.name;
      reviewUserElement.textContent = name;

      if (reviewerAvatar.dataset.originalAvatar) {
        const originalAvatar = reviewerAvatar.dataset.originalAvatar;
        reviewerAvatar.src = originalAvatar;
      }
    }

    $("#preview-review").removeClass("hidden");
    const reviewBodyTarget = $("#reviewbody")[0];
    const reviewBody = document.querySelector("#review_body").value;
    reviewBodyTarget.textContent = reviewBody;

    const ratingValue = $("#review_rating").val();
    this.showStar(ratingValue);
    const selectedTags = this.getSelectedTags();
    this.setTags(selectedTags);
  }

  showStar(rating) {
    const starContainer = document.getElementById("rate");
    starContainer.innerHTML = "";
    starContainer.classList.add("flex", "my-2");

    const starWidth = 18;
    const starHeight = 18;

    for (let i = 1; i <= 5; i++) {
      const starSVG = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      starSVG.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      starSVG.setAttribute("width", starWidth);
      starSVG.setAttribute("height", starHeight);
      starSVG.setAttribute("viewBox", "0 0 24 24");

      const starPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      starPath.setAttribute("fill", i <= rating ? "#FFD700" : "#C0C0C0");
      starPath.setAttribute(
        "d",
        "M12 2 l2.47 7.56 h8.12 l-6.56 5.38 2.47 7.44-6.47-5.38-6.47 5.38 2.47-7.44-6.56-5.38 h8.12 z"
      );

      starSVG.appendChild(starPath);
      starContainer.appendChild(starSVG);
    }
  }

  setTags(tags) {
    const container = document.getElementById("reviewtags");
    container.innerHTML = "";

    const ul = document.createElement("ul");
    ul.className =
      "m-[8px_0_0_0] px-0 py-[6px] flex flex-wrap items-center gap-2";

    tags.forEach((tag) => {
      const li = document.createElement("li");
      li.className = "list-none";

      const a = document.createElement("a");
      a.href = "javascript:void(0)";
      a.className = "experencise-tag";
      a.textContent = tag;

      li.appendChild(a);
      ul.appendChild(li);
    });
    container.appendChild(ul);
  }

  getSelectedTags() {
    var selectedTags = [];
    $("#select6 option:selected").each(function () {
      selectedTags.push($(this).val());
    });
    return selectedTags;
  }

  increment(event) {
    event.preventDefault();
    const reviewId = this.element.dataset.reviewId;
    const reviewKey = this.element.dataset.reviewKey;
    const thumbIcon = this.element.querySelector(".icon-thumb-up");
    const thumbIconSolid = this.element.querySelector(".icon-thumb-up-solid");
    const trgt = this.element.querySelector("#helpful-icon");

    if (!thumbIcon && !thumbIconSolid) {
      console.error("Thumb icon not found");
      return;
    }

    const isHelpful = !thumbIconSolid;

    fetch(`/people/${reviewKey}/reviews/${reviewId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ helpful: isHelpful }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to toggle counter");
        }
        return response.json();
      })
      .then((data) => {
        const counterElement = this.element.querySelector(
          '[data-target="review.counter"]'
        );
        if (counterElement) {
          const currentCount = parseInt(counterElement.textContent);
          const updateCounterText = (count) => {
            counterElement.textContent = `${count} ${
              count === 1 ? "Person" : "People"
            } found this helpful`;
          };

          if (isHelpful) {
            updateCounterText(currentCount + 1);
            trgt?.classList.remove("icon-thumb-up");
            trgt?.classList.add("icon-thumb-up-solid");
          } else {
            updateCounterText(currentCount - 1);
            trgt?.classList.remove("icon-thumb-up-solid");
            trgt?.classList.add("icon-thumb-up");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  toggleExpand(event) {
    event.preventDefault();

    const clickedElement = event.target;
    if (clickedElement.classList.contains("toggle-expand")) {
      const reviewId = clickedElement.getAttribute("data-review-id");
      const expandableContents = document.querySelectorAll(
        `.reply-list[data-review-target="${reviewId}"]`
      );

      expandableContents.forEach((content) => {
        content.classList.toggle("hidden");
      });
    }
  }

  toggleExpandFiveMore() {
    const moreTags = this.moreTagsTargets;

    moreTags.forEach((tag) => tag.classList.toggle("hidden")); // Toggle visibility of additional tags
  }

  toggleList(event) {
    const element = event.currentTarget;
    const list = element.nextElementSibling;
    list.classList.toggle("hidden");
  }

  closeReply(event) {
    const parentID = $(event.target)
      ?.parent()
      ?.parent()
      ?.find('input[name="review[parent_id]"]')
      ?.val();
    $(`[data-review-target="replyForm-${parentID}"]`).addClass("hidden");
  }
}
