// Custom TailwindCSS modals for confirm dialogs
//
// Example usage:
//
//   <%= button_to "Delete", my_path, method: :delete, form: {
//     data: {
//       turbo_confirm: "Are you sure?",
//       turbo_confirm_description: "This will delete your record. Enter the record name to confirm.",
//       turbo_confirm_text: "record name"
//     }
//   } %>

function insertConfirmModal(message, element, button) {
  let confirmInput = ""

  // button is nil if using link_to with data-turbo-confirm
  let confirmText = button?.dataset?.turboConfirmText || element.dataset.turboConfirmText
  let description = button?.dataset?.turboConfirmDescription || element.dataset.turboConfirmDescription || ""
  if (confirmText) {
    confirmInput = `<input type="text" class="focus:outline-none !ring-0 !p-[0_4px_0_0] outline-none w-full border-none !font-normal !leading-6 !text-[16px] " data-behavior="confirm-text" placeholder="Email address" required="required"/>`
  }
  let id = `confirm-modal-${new Date().getTime()}`

  let content = `
    <div id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table backdrop-blur-sm">
      <div class="table-cell align-middle">

        <div class="bg-white mx-auto rounded-[10px] shadow p-[30px] flex flex-col justify-center items-center max-w-[500px]">
          <h5 class="text-heading_color font-medium font-text_font text-[20px] leading-8">${message}</h5>
          <p class="text-[16px] font-normal leading-6 font-text_font text-[rgba(5,18,55,0.6)] text-center">${description}</p>

          <div class="mt-5 p-3 rounded-md bg-[rgba(5,18,55,0.04)] w-full">
            <span class="font-normal text-[12px] leading-4 tracking-[0.2px] font-text_font text-[rgba(5,18,55,0.6)]">
              To verify, type your email address to confirm and delete your account</span>
            <div class="w-full">
              <label for="top" class="font-medium text-[14px] leading-5 font-text_font text-[rgba(5,18,55,1)] mb-1">Email address</label>
              <div class="flex p-[8px_12px] items-center border-[2px] border-[rgba(5,18,55,0.08)] rounded-lg bg-white">
                  ${confirmInput}
                  <svg width="16" height="12" viewBox="0 0 18 18" fill="none"><path d="M16.5 9C16.5 10.875 14.25 15 9 15C3.75 15 1.5 10.875 1.5 9C1.5 7.125 3.75 3 9 3C14.25 3 16.5 7.125 16.5 9Z" stroke="#051237" stroke-opacity="0.32" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9Z" stroke="#051237" stroke-opacity="0.32" stroke-width="1.5" stroke-linecap="round"></svg>
              </div>
            </div>
          </div>

          <div class="flex w-full items-center gap-[10px] mt-5">
            <button data-behavior="cancel" class="secondary-button !w-full !font-medium border-[1px] !leading-5 !text-[14px] !p-[9px_20px] !border-[rgba(5,18,55,0.08)]">Cancel</button>
            <button data-behavior="commit" class="primary-button !text-[rgba(233,58,111,1)] !p-[9px_21px] !bg-[rgba(255,237,240,1)] !font-medium !text-[14px] !leading-5 w-full">Delete</button>
          </div>
        </div>
      </div>
    </div>
  `

  document.body.insertAdjacentHTML('beforeend', content)
  let modal = document.getElementById(id)

  // Focus on the first button in the modal after rendering
  modal.querySelector("button").focus()

  // Disable commit button until the value matches confirmText
  if (confirmText) {
    let commitButton = modal.querySelector("[data-behavior='commit']")
    commitButton.disabled = true
    modal.querySelector("input[data-behavior='confirm-text']").addEventListener("input", (event) => {
      commitButton.disabled = (event.target.value != confirmText)
    })
  }

  return modal
}

Turbo.setConfirmMethod((message, element, button) => {
  let dialog = insertConfirmModal(message, element, button)

  return new Promise((resolve, reject) => {
    dialog.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
      dialog.remove()
      resolve(false)
    }, { once: true })
    dialog.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
      dialog.remove()
      resolve(true)
    }, { once: true })
  })
})
