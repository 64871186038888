import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["existingSkillsInput"];

  removeSkill(event) {
    const skillElement = event.target.parentElement;
    const skillId = parseInt(event.target?.dataset?.skillId, 10);

    if (skillId) {
      const form = document.querySelector("form");
      let existingSkillsInput = this.existingSkillsInputTarget;

      if (existingSkillsInput) {
        let existingSkillIds = JSON.parse(existingSkillsInput.value || "[]");

        if (!existingSkillIds.includes(skillId)) {
          existingSkillIds.push(skillId);
        }

        existingSkillsInput.value = JSON.stringify(existingSkillIds);
      }
    }
    const skillName = skillElement.innerText;
    const skillSelector = document.getElementById("skills_mutiple");
    for (let i = skillSelector.options.length - 1; i >= 0; i--) {
      if (skillSelector.options[i].innerText === skillName) {
        skillSelector.remove(i);
      }
    }
    skillElement.parentElement.remove();
  }
}
