import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  previewImage() {
    this.clearPreviews();
    this.removeFiles("message_file");
    for (let i = 0; i < this.targets.element.files.length; i++) {
      let file = this.targets.element.files[i];
      const reader = new FileReader();
      this.createAndDisplayImagePreviewElements(file, reader);
    }
    $("#message_message_type").val("image");
    this.toggleVisibility();
  }

  previewFile() {
    this.clearPreviews();
    this.removeFiles("message_media");

    for (let i = 0; i < this.targets.element.files.length; i++) {
      let file = this.targets.element.files[i];
      const reader = new FileReader();
      this.createAndDisplayFilePreviewElements(file, reader);
    }
    this.toggleVisibility();
    $("#message_message_type").val("file");
  }

  toggleVisibility() {
    let preview = document.getElementById("attachment-previews");
    preview.classList.toggle("d-none");
  }

  createAndDisplayImagePreviewElements(file, reader) {
    reader.onload = () => {
      let element = this.constructPreviews(file, reader);
      element.src = reader.result;
      element.setAttribute("href", reader.result);
      element.setAttribute("target", "_blank");
      element.classList.add("attachment-preview");

      document.getElementById("attachment-previews").appendChild(element);
    };
    reader.readAsDataURL(file);
  }

  createAndDisplayFilePreviewElements(file, reader) {
    reader.onload = () => {
      let element = this.constructPreviews(file, reader);
      element.src = reader.result;
      element.setAttribute("href", reader.result);
      element.setAttribute("target", "_blank");
      element.classList.add("attachment-preview");

      document.getElementById("attachment-previews").appendChild(element);
    };
    reader.readAsDataURL(file);
  }

  constructPreviews(file, reader) {
    let element;
    let cancelFunction = (e) => this.removePreview(e);

    let removeUploadedFile = (e) => {
      this.removeFiles("message_file");
      this.removePreview(e);
    };
    const imageTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp"];
    const videoTypes = [
      "video/mp4",
      "video/quicktime",
      "video/webm",
      "video/ogg",
    ];
    const audioTypes = ["audio/mpeg", "audio/mp3", "audio/wav", "audio/ogg"];
    const documentTypes = ["application/pdf", "text/html"];

    if (imageTypes.includes(file.type)) {
      element = this.createImageElement(cancelFunction, reader);
    } else if (videoTypes.includes(file.type)) {
      element = this.createVideoElement(removeUploadedFile, file);
    } else if (audioTypes.includes(file.type)) {
      element = this.createAudioElement(removeUploadedFile, file);
    } else if (documentTypes.includes(file.type)) {
      element = this.createDefaultElement(removeUploadedFile, file);
    } else {
      element = this.createNotSupportedElement(removeUploadedFile);
    }
    element.dataset.filename = file.name;
    return element;
  }

  createImageElement(cancelFunction, reader) {
    let cancelUploadButton, element;
    const image = document.createElement("img");
    image.setAttribute("src", reader.result);
    image.classList.add("preview-image");
    element = document.createElement("div");
    element.classList.add("attachment-image-container");
    element.setAttribute("style", "position: relative;");
    element.appendChild(image);
    cancelUploadButton = document.createElement("span");
    cancelUploadButton.textContent = "X";
    cancelUploadButton.classList.add("cancel-upload-button");
    cancelUploadButton.onclick = cancelFunction;
    element.appendChild(cancelUploadButton);
    return element;
  }

  createAudioElement(cancelFunction, file) {
    let cancelUploadButton;

    const container = document.createElement("div");
    container.classList.add("audio-preview-container");

    const audio = document.createElement("audio");
    audio.src = URL.createObjectURL(file);
    audio.controls = true;
    container.appendChild(audio);

    cancelUploadButton = document.createElement("span");
    cancelUploadButton.textContent = "X";
    cancelUploadButton.classList.add("cancel-upload-button");
    cancelUploadButton.onclick = cancelFunction;
    container.appendChild(cancelUploadButton);

    return container;
  }

  createVideoElement(cancelFunction, file) {
    let cancelUploadButton;
    const container = document.createElement("div");
    container.classList.add("video-preview-container");

    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.controls = true;
    container.appendChild(video);

    cancelUploadButton = document.createElement("span");
    cancelUploadButton.textContent = "X";
    cancelUploadButton.classList.add("cancel-upload-button");
    cancelUploadButton.onclick = cancelFunction;
    container.appendChild(cancelUploadButton);

    return container;
  }

  createDefaultElement(cancelFunction, file) {
    let element, cancelUploadButton;
    const container = document.createElement("div");
    container.classList.add("document-preview-container");

    element = document.createElement("p");
    element.textContent = file.name;
    element.classList.add("document-preview-text");
    container.appendChild(element);

    cancelUploadButton = document.createElement("span");
    cancelUploadButton.textContent = "X";
    cancelUploadButton.classList.add("cancel-upload-button");
    cancelUploadButton.onclick = cancelFunction;
    container.appendChild(cancelUploadButton);

    return container;
  }

  createNotSupportedElement(removeUploadedFile) {
    let element;

    element = document.createElement("p");
    element.textContent = "File type not supported";
    element.classList.add("cm-not-supported");

    setTimeout(function () {
      element.remove();
      removeUploadedFile();
    }, 3000);

    return element;
  }

  removePreview(event) {
    const target = event.target.parentNode.closest(".attachment-preview");
    const dataTransfer = new DataTransfer();
    let fileInput = document.getElementById("message_media");
    let files = fileInput.files;
    let filesArray = Array.from(files);

    filesArray = filesArray.filter((file) => {
      let filename = target.dataset.filename;
      return file.name !== filename;
    });
    target.parentNode.removeChild(target);
    filesArray.forEach((file) => dataTransfer.items.add(file));
    fileInput.files = dataTransfer.files;
  }

  removeFiles(element_id) {
    let inputElement = document.getElementById(element_id);
    const dataTransfer = new DataTransfer();

    inputElement.files = dataTransfer.files;
  }

  clearPreviews() {
    document.getElementById("attachment-previews").innerHTML = "";
  }
}
