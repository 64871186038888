import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["language", "newLanguageButton", "languageContainer"];
  addMoreLanguage() {
    const newElement = this.languageTarget.cloneNode(true);
    const labels = newElement.getElementsByClassName("new_language_label");

    Array.from(labels).forEach((label) => label.remove());
    newElement.lastElementChild.lastElementChild.lastElementChild.classList.remove(
      "hidden"
    );

    const hiddenLanguage = newElement.querySelectorAll(".profile_languages_0");
    if (hiddenLanguage.length > 0) {
      hiddenLanguage[0].value = null;
    }

    const selects = newElement.querySelectorAll("select");
    selects.forEach((select) => {
      select.selectedIndex = 0;
    });

    const languageFields = document.querySelectorAll(".language-fields");
    const newIndex = languageFields.length;

    const newLanguageButton = this.newLanguageButtonTarget;
    if (newLanguageButton && newLanguageButton.parentNode) {
      newLanguageButton.parentNode.insertBefore(newElement, newLanguageButton);
    }
  }

  removeNode(event) {
    const element = event.currentTarget.closest(".language-fields");
    const languageId = event.currentTarget.dataset.id;

    if (languageId) {
      const removeLanguagesContainer = document.createElement("input");
      removeLanguagesContainer.type = "hidden";
      removeLanguagesContainer.name = "profile[remove_languages][]";
      removeLanguagesContainer.value = languageId;
      document
        .getElementById("edit_language_form")
        .appendChild(removeLanguagesContainer);
    }

    if (element) {
      element.remove();
    }
  }
}
