import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleSidebar() {
    $(".drawerbody").toggleClass("active");
    $("body").toggleClass("cart-drawer-pushtoleft");
  }

  toggleSubmenu() {
    this.element?.firstElementChild?.nextElementSibling?.classList?.toggle(
      "hidden"
    );
  }
}
