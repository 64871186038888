import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["postURLField"];

  connect() {
    const element = document.getElementById("community_post_listing");
    this.observer = new MutationObserver(this.handleMutation.bind(this));
    this.observer.observe(element, { childList: true });
  }

  handleMutation(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (
        mutation.type === "childList" &&
        mutation.addedNodes.length >= 1 &&
        mutation.addedNodes[0].nodeName.toLowerCase() === "div"
      ) {
        document.getElementById("__post_shimmer").classList.add("hidden");
      }
    });
  }

  postNow(event) {
    event.preventDefault();

    let url = this.postURLFieldTarget.value;

    if (url == null || url == "") {
      this.errorMessage(
        this.postURLFieldTarget,
        "This field is required",
        false
      );
      return;
    }

    if (!this.isValidURL(url)) {
      this.errorMessage(this.postURLFieldTarget, "Invalid URL", false);
      return;
    }

    this.postURLFieldTarget.value = url;
    event.currentTarget.form.requestSubmit();
    document.getElementById("__post_shimmer").classList.remove("hidden");

    setTimeout(() => {
      this.postURLFieldTarget.value = "";
    }, 1000);

    setTimeout(() => {
      const noFolder = document.getElementById("no-folders");
      if (noFolder) noFolder.remove();
    }, 3000);
  }

  isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  handleKeyUp(event) {
    this.errorMessage(event.currentTarget, "", true);
  }

  submitRequest(event) {
    event.preventDefault();
    event.currentTarget.requestSubmit();
  }

  errorMessage(element, message, is_removed = false) {
    if (is_removed) {
      element.classList.remove("!border-2", "!border-rose-500");
      document.getElementById("post_error_message")?.remove();
      return;
    }

    let spanElement = element.nextElementSibling;

    if (!spanElement || spanElement.tagName !== "SPAN") {
      spanElement = document.createElement("span");
      spanElement.id = "post_error_message";
      spanElement.className =
        "!text-rose-500 font-semibold text-sm mt-1 error_messages";
      element.insertAdjacentElement("afterend", spanElement);
    }

    element.classList.add("!border-2", "!border-rose-500");
    spanElement.textContent = message;
  }
}
