import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="subscription"
export default class extends Controller {
  static targets = ["cancelPlanForm"];

  handleKeyUp(event) {
    event?.currentTarget?.nextElementSibling?.remove();
    event?.currentTarget?.classList?.remove("!border-2", "!border-rose-500");
  }

  sendReceipt(event) {
    event.preventDefault();

    const ERROR_MESSAGE = "This field is required";
    const INVALID_EMAIL_ADDRESS = "invalid email address";

    const input =
      event?.currentTarget?.previousElementSibling?.firstElementChild;

    if (input && input.value == "") {
      this.errorMessage(input, ERROR_MESSAGE);
      return;
    }
    const isValidEmailAddress = this.isValidEmail(input.value);
    if (!isValidEmailAddress) {
      this.errorMessage(input, INVALID_EMAIL_ADDRESS);
      return;
    }

    event.currentTarget.form.requestSubmit();
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  errorMessage(element, error_message) {
    let spanElement = element.nextElementSibling;

    if (!spanElement || spanElement.tagName !== "SPAN") {
      spanElement = document.createElement("span");
      spanElement.classList.add(
        "!text-rose-500",
        "font-semibold",
        "text-sm",
        "mt-1"
      );
      element.insertAdjacentElement("afterend", spanElement);
    }

    element.classList.add("!border-2", "!border-rose-500");
    spanElement.textContent = error_message;
  }

  confirmCancelPlan(event) {
    event.preventDefault();
    const cancelPlanModal = document.getElementById("subscription-plan-modal");
    cancelPlanModal.classList.remove("hidden");
  }

  cancelPlan() {
    const form = document.getElementById("cancel_plan_form");
    form.requestSubmit();
  }
}
