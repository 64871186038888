import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.timeout = setTimeout(() => {
      this.dismiss();
    }, 3000);
  }

  dismiss() {
    this.messageTargets.forEach((message) => {
      message.style.transition = "opacity 0.5s ease";
      message.style.opacity = 0;
      setTimeout(() => {
        message.remove();
      }, 500);
    });
  }
}
