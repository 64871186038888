import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    userId: String,
  };

  connect() {
    if (this.userIdValue == "") {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
    }
  }
}
