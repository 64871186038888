import { Controller } from "@hotwired/stimulus";

let bar_instance = null;
export default class extends Controller {
  static targets = ["scoreContainer"];

  connect() {
    this.initializeBar();
  }

  initializeBar() {
    $("#container1").empty();
    const lucy = this.scoreContainerTarget;
    bar_instance = new ProgressBar.SemiCircle(lucy, {
      strokeWidth: 3,
      trailColor: "#E8E8E8",
      trailWidth: 3,
      easing: "easeInOut",
      duration: 1400,
      svgStyle: null,
      text: {
        value: "",
        alignToBottom: false,
      },
      step: (state, bar) => {
        bar.path.setAttribute("stroke", state.color);
        const value = Math.round(bar.value() * 100);
        if (value === 0) {
          bar.setText("");
        } else {
          bar.setText(
            `${value}<br> <span class='font-normal text-[12px] leading-4 not-italic !font-text_font'> <em class='font-normal text-[12px] leading-4 font-text_font not-italic text-[rgba(5,18,55,0.76)]'>  </em> </span>`
          );
        }
        bar.text.style.color = state.color;
        bar.text.style.top = "25%";
      },
    });
    bar_instance.text.style.fontFamily = "Inter";
    bar_instance.text.style.fontSize = "20px";
    bar_instance.text.style.fontWeight = "500";

    bar_instance.animate(lucy.dataset.value / 100); // Number from 0.0 to 1.0

    const linearGradient = `
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
          <stop offset="100%" stop-color="#5AC994"/>
        </linearGradient>
      </defs>
    `;
    bar_instance.svg.insertAdjacentHTML("afterBegin", linearGradient);

    const bar1 = new ProgressBar.SemiCircle(lucy, {
      strokeWidth: 3,
      easing: "easeInOut",
      duration: 1400,
      color: "#5AC994",
      trailColor: "#0512370A",
      trailWidth: 3,
      svgStyle: null,
    });
    bar1.animate(lucy.dataset.value / 100);
  }
}
