import { Controller } from "@hotwired/stimulus";
import * as OMS from "../vendor/oms";

export default class extends Controller {
  static targets = ["map"];

  async connect() {
    try {
      let mapString = this.data.get("map");
      mapString = mapString.slice(1, -1);
      let mapArray = mapString.split(",");
      const personIds = mapArray.map(Number);
      const mapData = await this.getMapViewData(personIds);
      this.initMapBox(mapData);
      $(".wave-skeleton").addClass("hidden");
      // if (mapData) {
      //   const personData = await this.validateImages(mapData);
      //   await this.initMap(personData);
      // } else {
      //   await this.initMap([]);
      // }
    } catch (error) {}
  }

  async getMapViewData(personIds) {
    try {
      const response = await fetch("/search/map_view_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ person_ids: personIds }),
      });
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching map view data:", error);
      return null;
    }
  }

  // async validateImages(mapData) {
  //   const newData = await Promise.all(
  //     mapData.map(async (person) => {
  //       const newP = person;
  //       const imageUrl = await this.fetchAvatarUrl(person);
  //       newP.avatar_url = imageUrl;
  //       return newP;
  //     })
  //   );
  //   return newData;
  // }

  // async fetchAvatarUrl(person) {
  //   try {
  //     const response = await fetch(person.avatar_url);
  //     if (response.ok) {
  //       const imageUrl = person.avatar_url;
  //       return imageUrl;
  //     } else {
  //       return "";
  //     }
  //   } catch (error) {
  //     return "";
  //   }
  // }

  // async initMap(mapData) {
  //   const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  //   const { AdvancedMarkerElement, PinElement } =
  //     await google.maps.importLibrary("marker");

  //   const map = new google.maps.Map(this.mapTarget, {
  //     zoom: 3,
  //     center: { lat: 40.7166798, lng: -74.0080133 },
  //     mapId: "people_map",
  //   });

  //   var oms = new OMS.OverlappingMarkerSpiderfier(map, {
  //     markersWontMove: true,
  //     markersWontHide: true,
  //     basicFormatEvents: true,
  //     keepSpiderfied: true,
  //     circleFootSeparation: 50,
  //   });

  //   var markers = [];

  //   const infoWindow = new google.maps.InfoWindow({
  //     content: "",
  //   });

  //   mapData.map((person) => {
  //     const personImage = document.createElement("img");

  //     personImage.src = person.avatar_url;
  //     personImage.style =
  //       "width: 36px; height: 36px; border-radius: 50%; border: 2px solid #D1E1FF;";

  //     const pinGlyph = new google.maps.marker.PinElement({
  //       glyph: person.name.charAt(0),
  //       glyphColor: "white",
  //     });

  //     const marker = new google.maps.marker.AdvancedMarkerElement({
  //       position: {
  //         lat: Number(person.latitude),
  //         lng: Number(person.longitude),
  //       },
  //       content: person.avatar_url ? personImage : pinGlyph.element,
  //     });

  //     // Popup design
  //     const styledHtml = `
  //       <div class="flex w-[250px] gap-[10px] overflow-hidden">
  //         ${
  //           person.avatar_url
  //             ? `
  //             <img src="${person.avatar_url}" class="border-[1px] border-[rgba(255,255,255,1)] w-[40px] h-[40px] object-cover rounded-[50px] flex-none" /> `
  //             : ""
  //         }
  //         <div class="flex flex-col">
  //           <a href="/people/${person.key}" target="_blank">
  //             <h2 class="text-[14px] m-0 font-medium leading-5 font-text_font">
  //               ${person.name}
  //             </h2>
  //           </a>
  //           ${
  //             person.city
  //               ? `
  //           <p class="text-[12px] mt-[3px] leading-4 font-text_font tracking-[0.2px] text-[rgba(5,18,55,0.6)]">
  //             ${person.position}
  //           </p> `
  //               : ""
  //           }
  //           ${
  //             person.city
  //               ? `
  //           <div class="flex gap-1 mt-[5px]">
  //             <a href="javascript:void(0)" class="mt-[2px] h-[11px] text-center w-[10px]">
  //               <i class="icon-location-marker text-text_color"></i>
  //             </a>
  //             <em class="text-[12px] leading-4 font-text_font font-normal tracking-[0.2px] not-italic text-[rgba(5,18,55,0.6)]">
  //             ${person.city}
  //             </em>
  //           </div>`
  //               : ""
  //           }
  //         </div>
  //       </div>
  //     `;
  //     marker.addListener("click", () => {
  //       infoWindow.setContent(styledHtml);
  //       infoWindow.open(map, marker);
  //     });

  //     markers.push(marker);
  //     oms.addMarker(marker);
  //   });

  //   const renderer = {
  //     render({ count, position }) {
  //       const icon = document.createElement("div");
  //       // Cluster styles
  //       icon.style =
  //         "background-color: rgba(253, 197, 0, 1); border-radius: 50%; width: 30px; height: 30px; border: 3px solid rgba(242, 176, 7, 1); display: flex; justify-content: center; align-items: center;";
  //       icon.innerHTML = `<p style='color: rgba(5, 18, 55, 0.88); font-size: 11px; font-weight: 700; line-height: 13px; letter-spacing: 0.2px; font-family: SfProDisplay'>
  //         ${String(count)}
  //         </p>`;

  //       return new google.maps.marker.AdvancedMarkerElement({
  //         position,
  //         title: String(count),
  //         zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  //         content: icon,
  //       });
  //     },
  //   };

  //   const markerCluster = new markerClusterer.MarkerClusterer({
  //     map,
  //     markers,
  //     renderer,
  //   });
  // }

  initMapBox(mapData) {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxleHRvcGxpbmUiLCJhIjoiY2x5NjFobzl4MDVvZTJrcHgxODY1YjJlNiJ9.2Ubqk43wiSfQTnlVya0W4w";
    const map = new mapboxgl.Map({
      container: "__flat_map",
      style: "mapbox://styles/mapbox/streets-v12",
      projection: "mercator",
      center: [-80, 30],
      zoom: 1.2,
    });

    mapData.forEach((person) => {
      const el = document.createElement("div");
      el.className = "custom-marker";
      el.style.backgroundImage = `url(${person.avatar_url})`;
      el.style.width = "35px";
      el.style.height = "35px";
      el.style.backgroundSize = "cover";
      el.style.borderRadius = "50%";
      const img = new Image();
      img.src = person.avatar_url;
      img.onload = () => {
        el.style.backgroundImage = `url(${person.avatar_url})`;
        this.createMarker(el, person, map);
      };
      img.onerror = () => {
        el.style.backgroundImage = `url(images/default_profile.svg)`;
        el.style.width = "35px";
        el.style.height = "35px";
        el.style.backgroundSize = "cover";
        el.style.borderRadius = "50%";
        el.dataset.name = "images/default_profile.svg";
        this.createMarker(el, person, map);
      };
    });
  }

  createMarker(element, person, map) {
    const marker =
      element instanceof HTMLElement ? new mapboxgl.Marker(element) : element;

    const person_name =
      person.name.length > 15 ? person.name.slice(0, 15) + "..." : person.name;

    const person_position =
      person.position.length > 25
        ? person.position.slice(0, 25) + "..."
        : person.position;

    let person_image = person.avatar_url;
    if (element?.dataset?.name) {
      person_image = element?.dataset?.name;
    }

    const content = `<div class="relative bg-white p-[18px] rounded-[10px] max-w-[260px] w-full">
    <div class="flex gap-[10px]">
        <img class="w-[38px] h-[38px] rounded-full" src="${person_image}" alt="user">
        <div class="flex flex-col">
            <div class="flex gap-[6px]">
                <span title="${
                  person.name
                }" class="text-[14px] font-medium leading-[20px] font-text_font text-heading_color"> ${person_name} </span>
            </div>
            ${
              person.position != null && person.position != "--"
                ? `<p title="${person.position}" class="font-normal mt-[3px] text-[12px] line-clamp-1 leading-4 not-italic tracking-[0.2px] font-text_font text-text_color">
            ${person_position}
          </p>`
                : ""
            }
            
            <ul class="p-0 m-[10px_0_0_0] flex flex-col gap-1">
            ${
              person.industry != null && person.industry != "--"
                ? `<li class="list-none">
                      <div class="flex justify-start items-center gap-[7px]">
                          <span class="h-4 w-4 flex items-center justify-center"><i class="icon-briefcase text-text_color"></i></span>
                          <em class="font-normal text-[12px] not-italic leading-4 font-text_font text-text_color block">${person.industry}</em>
                      </div>
                  </li>`
                : ""
            }

            ${
              person.city != null && person.city != ""
                ? `<li class="list-none">
                      <div class="flex justify-start items-center gap-[7px]">
                          <span class="h-4 w-4 flex items-center justify-center"><i class="icon-location-marker text-text_color"></i></span>
                          <em class="font-normal text-[12px] not-italic leading-4 font-text_font text-text_color block">${person.city}</em>
                      </div>
                  </li>`
                : ""
            }
            </ul>
        </div>
    </div>
      <a href="${window.location.origin}/people/${
      person.key
    }" class="secondary-button !p-[7px_16px] !font-medium mt-[10px] outline-none">View Details</a>
    </div>`;

    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(content);

    if (person.longitude && person.latitude) {
      marker
        .setLngLat([person.longitude, person.latitude])
        .setPopup(popup)
        .addTo(map);
    }
  }
}
