import { Controller } from "@hotwired/stimulus"
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {
  static targets = ["input", "textField"];

  connect() {
    const emojiButton = new EmojiButton();

    emojiButton.on('emoji', selection => {
      const textField = this.textFieldTarget;
      const currentText = textField.value;
      const cursorPosition = textField.selectionStart;
      const newText = currentText.slice(0, cursorPosition) + selection.emoji + currentText.slice(cursorPosition);
      textField.value = newText;
    });

    document.querySelector('.emoji-button').addEventListener('click', () => {
      emojiButton.togglePicker(document.querySelector('.emoji-button'));
    });
  }
}
