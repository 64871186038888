import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="case-studies"
let case_study_id = null;
const FILE_SIZE_VALIDATION = 5000;
const LOADING_SVG_ICON = `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>`;
const FILE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
<path d="M19 9V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H13M19 9L13 3M19 9H14C13.4477 9 13 8.55228 13 8V3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const ALLOWED_EXTENSIONS = ["pdf", "doc", "docx", "png", "jpg", "jpeg"];

export default class extends Controller {
  static targets = ["documentElement", "linkElement"];
  connect() {}

  selectType(event) {
    if (event.currentTarget.value === "document") {
      this.linkElementTarget.classList.add("hidden");
      this.documentElementTarget.classList.remove("hidden");
    } else {
      this.linkElementTarget.classList.remove("hidden");
      this.documentElementTarget.classList.add("hidden");
    }
  }

  openDeleteModal(event) {
    case_study_id = event.currentTarget.dataset.casestudy;
    document
      .getElementById("case_study_delete_modal")
      .classList.remove("hidden");
  }

  deleteCaseStudy(event) {
    event.preventDefault();

    if (case_study_id == null) {
      return;
    }
    document.getElementById(`__case_study_${case_study_id}`).requestSubmit();
    document.getElementById("case_study_delete_modal").classList.add("hidden");
  }

  closeModal(event) {
    document.getElementById("case_studies_modal").innerHTML = "";
  }

  editModal(event) {
    event.currentTarget.parentElement.requestSubmit();
  }

  generateFileInfo(file) {
    if (!file) return;

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / 1024;
    const fileName = file.name;
    const fileExtension = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    return {
      fileName: fileName,
      fileSizeInMB: fileSizeInMB,
      fileExtension: fileExtension,
      fileSizeInBytes: fileSizeInBytes,
    };
  }

  previewDocument(event) {
    const input = event.target;

    const cs_show_loader = document.getElementById("cs_show_loader");
    const submit_button = document.getElementById("cs_submit_button");
    const cs_doc_error = document.getElementById("cs_doc_error");
    const cs_doc_delete_button = document.getElementById(
      "cs_doc_delete_button"
    );

    const file_input = document.getElementById("cs_document");

    if (input.files && input.files[0]) {
      const reader = new FileReader();
      const file = this.generateFileInfo(input.files[0]);

      cs_doc_error.textContent = "";
      submit_button.disabled = true;
      cs_doc_delete_button.classList.add("hidden");
      cs_show_loader.className = "block bg-black opacity-50 rounded-lg";
      file_input.previousElementSibling.innerHTML = LOADING_SVG_ICON;

      setTimeout(() => {
        if (file.fileSizeInMB > FILE_SIZE_VALIDATION) {
          file_input.previousElementSibling.innerHTML = `<i class="icon-plus text-text_color"></i>`;
          cs_show_loader.className = "";
          submit_button.disabled = false;
          cs_doc_error.textContent =
            "File size is too large. Max allowed file size is 5MB";
          return;
        }

        if (!ALLOWED_EXTENSIONS.includes(file.fileExtension)) {
          file_input.previousElementSibling.innerHTML = `<i class="icon-plus text-text_color"></i>`;
          cs_show_loader.className = "";
          submit_button.disabled = false;
          cs_doc_error.textContent = "File type is not allowed";
          return;
        }

        cs_show_loader.className = "";
        submit_button.disabled = false;

        const iconFileName = `<span class="text-xs font-medium mt-2" title="${
          file.fileName
        }">${
          file.fileName.length > 6
            ? file.fileName.substring(6, file.fileName) + ".."
            : file.fileName
        }</span>`;

        file_input.previousElementSibling.innerHTML = `<div class="flex justify-center items-center flex-col">${
          FILE_ICON + iconFileName
        }</div>`;

        cs_doc_delete_button.classList.remove("hidden");

        if (file_input.getAttribute("name") == null) {
          file_input.setAttribute("name", `case_study[document]`);
        }

        reader.readAsDataURL(input.files[0]);
      }, 1000);
    }
  }

  deleteDocument(event) {
    const cs_doc_delete_button = document.getElementById(
      "cs_doc_delete_button"
    );
    const cs_show_loader = document.getElementById("cs_show_loader");

    const element = document.getElementById("cs_document");

    const target_label = element.previousElementSibling;
    target_label.innerHTML = `<i class="icon-plus text-text_color"></i>`;
    cs_show_loader.className = "";

    element.removeAttribute("name");

    cs_doc_delete_button.classList.add("hidden");
  }
}
