import { Controller } from "@hotwired/stimulus";

let talent_profile_ids = [];
export default class extends Controller {
  static targets = ["link", "input", "continueButton", "errorMessage"];

  checkAllTalents(el) {
    const checkboxes = document.querySelectorAll(".talent_checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = el.target.checked ? true : false;

      if (el.currentTarget.checked) {
        talent_profile_ids.push(checkbox.dataset.id);
      }
    });

    if (el.target.checked) {
      document.getElementById("talent_profile_ids").value =
        JSON.stringify(talent_profile_ids);
      document.getElementById("removeProfileButton").classList.remove("hidden");
    } else {
      talent_profile_ids.length = 0;
      document.getElementById("removeProfileButton").classList.add("hidden");
    }
  }
  filterModal() {
    document.getElementById("filter_tags_modal").classList.remove("hidden");
  }

  totalCheckLength() {
    const checkboxes = document.querySelectorAll(".talent_checkbox");
    let checkedCount = 0;

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount++;
      }
    });
    return checkedCount;
  }

  checkItem(el) {
    const checkboxes = document.querySelectorAll(".talent_checkbox").length;
    const selectAll = document.getElementById("select_all_talent");
    if (!el.target.checked) {
      selectAll.checked = false;
      document.getElementById("removeProfileButton").classList.add("hidden");
    } else {
      document.getElementById("removeProfileButton").classList.remove("hidden");
    }
    if (checkboxes == this.totalCheckLength()) {
      selectAll.checked = true;
    }

    const talentIndex = talent_profile_ids.indexOf(
      el?.currentTarget?.dataset?.id
    );
    if (talentIndex != -1) {
      talent_profile_ids.splice(talentIndex, 1);
    } else {
      talent_profile_ids.push(el.currentTarget.dataset.id);
    }

    document.getElementById("talent_profile_ids").value =
      JSON.stringify(talent_profile_ids);
  }

  deleteProfile(event) {
    event.currentTarget.form.requestSubmit();
  }

  inviteCandidates() {
    document
      .getElementById("invite_condidates_modal")
      .classList.remove("hidden");
  }

  updateTitle() {
    const title = this.inputTarget.value.trim();
    if (title) {
      this.continueButtonTarget.disabled = false;
      this.errorMessageTarget.classList.add("hidden");
    } else {
      this.continueButtonTarget.disabled = true;
      this.errorMessageTarget.classList.remove("hidden");
    }
  }

  continue() {
    const title = this.inputTarget.value.trim();
    if (title == "") {
      this.errorMessageTarget.classList.remove("hidden");
      return;
    }
    const jobTitle = title;
    const referer = this.element.dataset.refererId;
    const linkModal = document.getElementById("link_modal");

    const baseLink = `${window.location.origin}/applicants/signup`;
    const generatedLink = `${baseLink}?referer=${referer}&job_title=${encodeURIComponent(
      jobTitle
    )}`;

    linkModal.querySelector('[data-saveitems-target="link"]').value =
      generatedLink;

    this.element.classList.add("hidden");
    linkModal.classList.remove("hidden");
  }

  async copyToClipboard() {
    try {
      const link = this.linkTarget.value;
      await navigator.clipboard.writeText(link);

      this.element.classList.add("hidden");
    } catch (error) {
      console.error("Failed to copy the link to clipboard", error);
    }
  }

  closeInviteModal() {
    document.getElementById("invite_condidates_modal").classList.add("hidden");
  }
}
