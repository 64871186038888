import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hybrid-search"
export default class extends Controller {
  connect() {
    const select6 = jQuery("#select6");
    this.submitByFilters(select6);
    const select7 = jQuery("#select7");
    this.submitByFilters(select7);

    jQuery("#select8")
      .select2()
      .on("change", (e) => {
        e.currentTarget.form.requestSubmit();
      });
  }

  submitByFilters(selectElement) {
    selectElement.off("change");
    selectElement.select2().on("change", (e) => {
      e.currentTarget.form.requestSubmit();
    });
  }

  submitByScores(event) {
    event.currentTarget.form.requestSubmit();
  }

  submitByProfileFeatures(event) {
    event.currentTarget.form.requestSubmit();
  }

  disconnect() {
    $(this.element).find("#select6").select2("destroy");
    $(this.element).find("#select7").select2("destroy");
    $(this.element).find("#select8").select2("destroy");
    $(this.element).find("#skill_search").select2("destroy");
  }
}
