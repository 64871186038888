import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["confirmation", "instructions", "button", "confirmtext"];

  connect() {
    jQuery("#select_city_dropdown").off("change");
    jQuery("#select_city_dropdown")
      .select2()
      .on("change", (e) => {
        var city_name = jQuery(e.target).val();
        this.renderProfiles(city_name);
      });
  }

  disconnect() {
    $(this.element).find("#select_city_dropdown").select2("destroy");
  }

  send() {
    this.buttonTarget.style.visibility = "hidden";
    this.instructionsTarget.style.visibility = "hidden";
    this.confirmationTarget.classList.remove("hidden");
    this.confirmtextTarget.classList.remove("hidden");
  }

  renderProfiles(city) {
    if (city != null) {
      fetch(`${window.location.origin}/claim_profiles/search`, {
        method: "POST",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          city: city,
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          Turbo.renderStreamMessage(html);
        });
    }
  }

  openModal() {
    $("#claim-profile-details-modal").removeClass("hidden");
  }

  closeClaimProfile() {
    $("#claim-profile-details-modal").addClass("hidden");
  }

  closeModal() {
    document.getElementById("one-click-claim-profile").style.display = "none";
  }
}
