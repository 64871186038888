import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    publicKey: String,
    clientSecret: String,
  };

  async connect() {
    setTimeout(async () => {
      this.stripe = Stripe(this.publicKeyValue);
      this.checkout = await this.stripe.initEmbeddedCheckout({
        clientSecret: this.clientSecretValue,
      });
      this.checkout.mount(this.element);
    }, 1000);
  }

  disconnect() {
    this.checkout.destroy();
  }
}
